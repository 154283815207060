<template>
  <div class="group flex items-center justify-start mb-4 sm:mb-0 lg:w-1/4">
     <input v-if="editingName" type="text" class="w-full text-sm border px-2 py-1 border-input-border focus:border-input-border-focus rounded" v-model="name" @keyup.enter="submitName" />
     <h2 v-else @click="toggleEditingName"
         class="font-bold text-base whitespace-nowrap text-dark-900"
         :title="name">{{name}}</h2>
     <button @click="toggleEditingName"
             class="ml-2"
             :title="$t(button_title)">
      <SvgPencil v-if="!editingName" class="w-4 h-4 text-brand-link"/>
      <SvgSmallClose v-else />
    </button>
  </div>
</template>

<script setup
        lang="ts">

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  button_title: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['name_submitted']);

const editingName = ref(false);
const name = ref(props.name);

const toggleEditingName = () => {
  editingName.value = !editingName.value;
}

const submitName = () => {
  emit('name_submitted', name.value);
  editingName.value = false;
}

</script>

<style scoped>

</style>